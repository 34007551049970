import { useForm } from "react-hook-form";
import { useFetch } from "../../../hooks/useFecth";
import { AppContext } from "../../../context/AppContext";
import { useTranslation } from "react-i18next";
import { LocationContext } from "../../../context/LocationContext";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { Checkbox, FormControlLabel, Step, StepButton, Stepper, TextField } from "@mui/material";
import darkEmptyPizza from "../../../assets/img/empty_pizza_dark.svg";
import lightEmptyPizza from "../../../assets/img/empty_pizza.svg";
import pizzaImg from "../../../assets/img/pizza.jpg";
import pizzaPng from "../../../assets/img/pizza-dark.png";
import drinkImg from "../../../assets/img/drink.jpg";
import drinkPng from "../../../assets/img/drink-dark.png";
import simpleImg from "../../../assets/img/burger.jpg";
import simplePng from "../../../assets/img/burger-dark.png";
import combosImg from "../../../assets/img/combos.jpg";
import combosPng from "../../../assets/img/combos-dark.png";
import dessertPng from "../../../assets/img/desseet-dark.png";
import dessertImg from "../../../assets/img/dessert.jpg";
import HandlerApp from "../../../utils/handlerApp";
import BoldSelect from "../../../utils/BoldSelect";
import modalSimple from "../../../assets/img/modal-simple.jpg";
import modalCustom from "../../../assets/img/modal-custom.jpg";
import pizzaSilueta from "../../../assets/img/pizza-silueta.jpg";
import modalSimplePng from "../../../assets/img/modal-simple-dark.png";
import modalCustomPng from "../../../assets/img/modal-custom-dark.png";
import InputAdornment from '@mui/material/InputAdornment';
import pizzaSiluetaPng from "../../../assets/img/pizza silueta.png";
import BoldFilesUpload from "../../../utils/BoldFilesUpload";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BoldMultiplesSelect from "../../../utils/BoldMultiplesSelects";
import BoldSelectValidated from "../../../utils/BoldSelectValidated";

const ProductsForms = () => {
    const endpoint          = "administration/products";
    const [t, i18n]         = useTranslation("global");
    const [title, setTitle] = useState(t('productComponent.createProduct'));
    const api               = useFetch();
    const nav               = useNavigate();
    const handlerApp        = HandlerApp();
    const profile           =  handlerApp.getProfile()

    const { id } = useParams();

    const { app, modeTheme }       = useContext(AppContext);
    const { companies, locations } = useContext(LocationContext);

  
    const [isPizza, setIsPizza]                             = useState(false)
    const [printers, setPrinters]                           = useState([]);
    const [taxgroup, setTaxesGroup]                         = useState([]);
    const [sizePrices, setSizePrices]                       = useState(false)
    const [description, setDescription]                     = useState('');
    const [permissions, setPermissions]                     = useState([]);
    const [productsView, setProductsView]                   = useState([]);
    const [disableButton, setDisableButton]                 = useState(false);
    const [subcategoryData, setSubcategoryData]             = useState([]);
    const [printersLocations, setPrintersLocations]         = useState([]);
    const [disableSelectCompany, setDisableSelectCompany]   = useState(false);
    const [productAssociateCombo, setProductAssociateCombo] = useState(false);
    const [productAsociateDrink, setProductAsociateDrink]   = useState(false);

    // funciones de stepper
    const [completed, setCompleted]   = useState({});
    const [activeStep, setActiveStep] = useState(0);

    // Companies
    const [companiesOptions, setCompaniesOptions] = useState([]);
    // categories
    const [categoriesList, setCategoriesList]     = useState([]);
    const [category, setCategory]                 = useState(null);

    // subcategories
    const [subcategoriesList, setSubcategoriesList] = useState([]);
    const [subcategory, setSubcategory]             = useState("");

    //items
    const [itemList, setItemList] = useState([]);
    // combos
    const [comboList, setComboList] = useState([]);
    // drinks
    const [drinkList, setDrinkList] = useState([]);
    // pizzas configuration
    const [selectedSize, setSelectedSize]               = useState("");
    const [pizzasConfiguration, setPizzasConfiguration] = useState([]);


    const [images, setImages]                       = useState([]);
    const [sizeSelected, setSizeSelected]           = useState([])
    const [sizeSelectedPizza, setSizeSelectedPizza] = useState([])
    
    // Data
    const [formData, setFormData] = useState({
        company_id: "",
        product_name: "",
        product_group: "",
        product_sku: "",
        product_price: "",
        product_cost: "",
        product_currency: "",
        product_link: null,
        product_description: "",
        product_short_description: "",
        product_slug: "",
        product_not_available: false,
        product_details: [],
        product_view: "",
        product_drink: false,
        drinks_ids: [],
        product_combo: false,
        combos_ids: [],
        product_pizza: false,
        productpizza_id: null,
        product_dessert: false,
        ingredients: [],
        extras: [],
        category_id: '',
        subcategory_id: null,
        images: [],
        printer_id: '',
        productlocation_use_stock: false,
        productlocation_min_stock: '',
        productlocation_max_stock: '',
        productlocation_stock: '',
        taxgroup_id: ''
    });

    const { register, handleSubmit, formState: { errors }, setValue, control } = useForm()


    useEffect(() => {
        if (profile > 2 && id === undefined) {
            if(locations.length === 1 && id === undefined){
                let lo = locations[0]?.location_id;
                getParamsUpdateProductLocation(lo);
            }
            getParamsUpdate();
        }
    }, [profile])

    useEffect(() => {
        setPermissions(app.permissions[endpoint])
    }, [app])

    useEffect(() => {
        setTitle(t('productComponent.createProduct'));
        setDescription(t('description.products_create'));
        if (id !== undefined) {
            setTitle(t('productComponent.updateProduct'));
            setDescription(t('description.products_form'));
        }
    }, [t])

    useEffect(() => {
        if (companies.length > 0) {
            const transformedCompanies = companies.map(company => ({
                code: company.company_id,
                name: company.company_name
            }));
            setCompaniesOptions(transformedCompanies)
        }   
      
    }, [companies])

    useEffect(() => {
        if (id !== undefined) {
            sendRequest();
            setDisableSelectCompany(true);
        } else {
            handlerApp.setLoader(false);
        }
    }, [])

    useEffect(() => {
        if (category !== null) {
            handlerApp.setLoader(true);
            api.get(endpoint, {
                action: 'getSubcategories',
                category: category
            })
            .then(response => {
                if (response.success) {
                    setSubcategoriesList(response.data);
                    handlerApp.setLoader(false);
                } else {
                    setFormData({
                        ...formData,
                        subcategory_id: null,
                    })
                    setSubcategoriesList(null);
                    handlerApp.setLoader(false);
                }
            }).catch(error => {
                handlerApp.showError();
                handlerApp.setLoader(false);
            })
        }
    }, [category]);

    useEffect(() => {
        if (category !== null) {
            sendRequestForSubcategories();
            const filterSubcategoriesByCategoryID = (subcategoryData, categoryIdToFind) => {
                return subcategoryData.filter(subcategory => subcategory.category_id === categoryIdToFind);
            };
        }
    }, [category]);

    useEffect(() => {
        setFormData(prevState => ({
            ...prevState,
            product_name: prevState.product_name.replace(/\s*\(.*?\)\s*/, ` (${selectedSize})`),
        }));
        setValue("product_name", formData.product_name.replace(/\s*\(.*?\)\s*/, ` (${selectedSize})`))
    }, [selectedSize]);

    const handleImages = (e) => {
        setImages(e)
    }

    const handleDependencies = (data) => {
        let d = filterData(data, formData);
        setCategory(data.category_id);

        // extras
        let auxExtras = [];
        data.extras?.forEach(e => {
            auxExtras.push(e.item_id);
        })
        d.extras = auxExtras;

        // ingredients
        let auxIngredients = [];
        data.ingredients?.forEach(e => {
            auxIngredients.push(e.item_id);
        })
        d.ingredients = auxIngredients;
        setFormData(d);
    }

    const filterData = (data, formData) => {
        let finalData = {};
        for (let key in formData) {
            finalData[key] = data[key];

            setValue(key, data[key]);
        }
        return finalData;
    }

    // Get Product
    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(endpoint + "/" + id,)
            .then(response => {
                if (response.success) {
                    let data = response.data[0];
                    handleDependencies(data);
                    getParamsUpdate(data.company_id)
                    if (data.drinks_ids.length > 0 && data.drinks_ids[0] !== '') {
                        setProductAsociateDrink(true);
                    }
                    if (data.combos_ids.length > 0 && data.combos_ids[0] !== 0) {
                        setProductAssociateCombo(true);
                    }
                    if (data.product_view === "PIZZA" && data.product_pizza) {
                        setActiveStep(1)
                    }
                    handlerApp.setLoader(false);
                    setDisableButton(false)
                } else {
                    handlerApp.showError(response.message);
                    setDisableButton(true)
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.setLoader(false);
                handlerApp.showError();
            })
    }

   const getParamsUpdate = (company_id = null, location_id = null) => {
    handlerApp.setLoader(true);
        api.get(endpoint, {
            action: "getParamsUpdate",
            company_id,
            location_id
        }).then(response => {
            if (response.success) {
                setCategoriesList(handlerApp.getResultFromObject(response.data, 'categories'));
                setProductsView(handlerApp.getResultFromObject(response.data, 'product_view'));
                setComboList(handlerApp.getResultFromObject(response.data, 'combo'));
                setItemList(handlerApp.getResultFromObject(response.data, 'items'));
                setDrinkList(handlerApp.getResultFromObject(response.data, 'drinks'));

                // nombre de configuracion de pizza personalizado.
                let pizza = JSON.parse(response.data[0].pizzas_configuration)
                /* for(let e of pizza){
                    e.name = e.name+", slices: "+e.slices+", diametter: "+e.diametter;
                } */
                pizza = pizza.map(item => {
                    return { ...item, selected: false }
                })
                setPizzasConfiguration(pizza);
                handlerApp.setLoader(false);
            } else {
                handlerApp.handlerResponse(response)
                handlerApp.setLoader(false);
            }

        })
        .catch(error => {
            handlerApp.setLoader(false);
            handlerApp.showError();
        })
    }

    const getParamsUpdateProductLocation = (val = null) => {
        handlerApp.setLoader(true);
        api.get('configuration/locations/products', {
            action: 'getParamsUpdate',
            location: val
        })
        .then(response => {
            if (response.success) {
                setPrinters(handlerApp.getResultFromObject(response.data, 'printers'))
                setTaxesGroup(handlerApp.getResultFromObject(response.data, 'tax_group'));
                setPrintersLocations(handlerApp.getResultFromObject(response.data, 'printers_locations'))
                handlerApp.setLoader(false);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError();
            handlerApp.setLoader(false);
        })
    }

    const handleCategory = (value) => {
        setCategory(value);
        setSubcategory("");
        setFormData({ ...formData, category_id: value, subcategory_id: subcategory });
        setSubcategoriesList([]);
    }

    const handleSubcategory = (value) => {
        setSubcategory(value);
        setFormData({ ...formData, subcategory_id: value })
    }

    // --------------- FORM -----------------
    const handleForm = (e, name = null, dependency = null) => {
        const key = name || e.target.name;
        let value = e.target.value;

        const regex = /^\d+(\.\d{0,2})?$/;

        const isPriceOrCost = key === "product_price" || key === "product_cost";
        
        if (!isPriceOrCost || (isPriceOrCost && (regex.test(value) || value === ''))) {
            updateFormData(key, value,dependency);
        }
    };

    const updateFormData = (key, value,dependency) => {
        setFormData(prevState => ({
            ...prevState,
            [key]: value,
            category_id: key === 'category_id' ? null : prevState.category_id
        }));

        setValue(key, value);

        if (dependency) {
            dependency(value);
        }
    };

    const handleCancel = (e) => {
        e.preventDefault();
        nav(-1);
    }

    const returnObjectFromMultipleSelect = (options, values) => {
        let element = [];
        values?.forEach(val => {
            options.forEach(opt => {
                if (val === opt.code) {
                    element.push(opt);
                }
            })
        })
        return element;
    }
    
    const handleValidationSubmitStep2 = (data) => {
        if (formData.product_view === "PIZZA" && formData.product_pizza == true) {
            setIsPizza(true);
        }else if (formData.product_view !== "MODAL_DRINK_SIMPLE" && formData.product_view !== "MODAL_DRINK_SEMICUSTOM" && formData.product_dessert !== true) {
            handleStep(2);
        }
        else {
            if (id === undefined && locations.length === 1) {
                handleStep(3);
                
            }else{
                handleStep(4);
            }
        }
    };

    const handleSubmitLocal = (e) => {
        e.preventDefault();
        let extras = returnObjectFromMultipleSelect(itemList, formData.extras);
        let ingredients = returnObjectFromMultipleSelect(itemList, formData.ingredients);
        let combos_ids = returnObjectFromMultipleSelect(comboList, formData.combos_ids);
        let drinks_ids = returnObjectFromMultipleSelect(drinkList, formData.drinks_ids);
        let location_id = handlerApp.getLocation();
        formData.product_group = formData.product_group.trim()
        let resultPizzas = sizeSelectedPizza.map(pizza => {
            let formPizzaData = { ...formData }
            formPizzaData.product_group = formPizzaData.product_name
            formPizzaData.product_name = formPizzaData.product_name + " (" + pizza.name + ")"
            formPizzaData.product_price = pizza.price
            formPizzaData.productpizza_id = pizza.code
            if (formPizzaData.category_id === null || formPizzaData.category_id === '') {
                const uncategorized = categoriesList.find(category => category.name === "Uncategorized");
                formPizzaData.category_id = uncategorized.code;
            }
            return { ...pizza, ...formPizzaData, extras, ingredients, combos_ids, drinks_ids, images, location_id }
        })
        
        if (formData.category_id === null || formData.category_id === '') {
            const uncategorized = categoriesList.find(category => category.name === "Uncategorized");
            formData.category_id = uncategorized.code;
        }
        let body = {}
        handlerApp.setLoader(true);
        if (formData.product_view === "PIZZA") {
            body = resultPizzas
        } else {
            body = { ...formData, extras, ingredients, combos_ids, drinks_ids, images, location_id };
        }

        api.post(endpoint, body)
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                handlerApp.setLoader(false);
                setCategory(null);
                setFormData({
                    product_name: "",
                    product_group: "",
                    product_sku: "",
                    product_price: "",
                    product_cost: "",
                    product_currency: "",
                    product_link: null,
                    product_description: "",
                    product_short_description: "",
                    product_slug: "",
                    product_not_available: false,
                    product_details: [],
                    product_view: "",
                    product_drink: false,
                    drinks_ids: [],
                    product_combo: false,
                    combos_ids: [],
                    product_pizza: false,
                    productpizza_id: null,
                    product_dessert: false,
                    ingredients: [],
                    extras: [],
                    category_id: '',
                    subcategory_id: null,
                    images: [],

                })
                handleStep(0)
                setSizePrices(false)
                setSizeSelected([])
                let updatedSelected = pizzasConfiguration.map(pizza => {
                    return { ...pizza, selected: false };
                })
                setPizzasConfiguration(updatedSelected);
                nav("/" + endpoint);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }

        })
        .catch(error => {
            handlerApp.showError();
            handlerApp.setLoader(false);
        })
    }

    const handleUpdate = (e) => {
        e.preventDefault();

        let extras;
        let ingredients;
        let combos_ids;
        let drinks_ids;
        if (formData.extras) {
            extras = returnObjectFromMultipleSelect(itemList, formData.extras);
        }
        if (formData.ingredients) {
            ingredients = returnObjectFromMultipleSelect(itemList, formData.ingredients);
        }
        if (formData.combos_ids) {
            combos_ids = returnObjectFromMultipleSelect(comboList, formData.combos_ids);
        }
        if (formData.drinks_ids) {
            drinks_ids = returnObjectFromMultipleSelect(drinkList, formData.drinks_ids);
        }
        let location_id = handlerApp.getLocation();
        formData.product_group = formData.product_group ? formData.product_group.trim() : null

        if (formData.category_id === null || formData.category_id === '') {
            const uncategorized = categoriesList.find(category => category.name === "Uncategorized");
            formData.category_id = uncategorized.code;
        }
        let body = { ...formData, extras, ingredients, combos_ids, drinks_ids, images, location_id };
        handlerApp.setLoader(true);
        api.put(endpoint + "/" + id, body)
            .then(response => {
                if (response.success) {
                    handlerApp.showOk(response);
                    handlerApp.setLoader(false);
                    setSelectedSize("");
                    nav("/" + endpoint);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError();
                handlerApp.setLoader(false);
            })
    }

    const handleStep = (step) => {
        setActiveStep(step);
    }

    const completedStep = (index, value) => {
        if (value === null) {
            setCompleted({ ...completed, [index]: true })
        } else {
            setCompleted({ ...completed, [index]: value })
        }
    }

    const back = () => {
        nav(-1);
    }
    
    const sendRequestForSubcategories = () => {
        handlerApp.setLoader(true);
        api.get("administration/subcategories")
        .then(response => {
            setSubcategoryData(response.data);
        })
    }

    const handleKeyDow = (event) => {
        // Verifica si la tecla presionada es Enter
        if (event.key === 'Enter') {
            // Evita el comportamiento predeterminado del formulario
            event.preventDefault();
        }
    };

    const selectSizePizza = (e, item) => {
        const clickedElement = e.currentTarget
        if (clickedElement.className == "card-option selected") {
            let updatedSize = sizeSelected.filter(size => size !== item.code);
            let updatedSelected = pizzasConfiguration.map(pizza => {
                if (pizza.code === item.code) {
                    return { ...pizza, selected: false };
                } else {
                    return pizza
                }
            })
            setPizzasConfiguration(updatedSelected)
            setSizeSelected(updatedSize)
        } else {
            let updatedSize = [...sizeSelected, item.code];
            let updatedSelected = pizzasConfiguration.map(pizza => {
                if (pizza.code === item.code) {
                    return { ...pizza, selected: true };
                } else {
                    return pizza
                }
            })
            setPizzasConfiguration(updatedSelected)
            setSizeSelected(updatedSize)
        }
    }

    const filterSizes = () => {
        let result = pizzasConfiguration.filter(pizza => sizeSelected.includes(pizza.code))
        result = result.map(pizza => {
            return { ...pizza, price: "" }
        })
        setSizeSelectedPizza(result)
    }

    const handleChangeSizePrice = (value, code) => {
        let pizzasUpdate = [...sizeSelectedPizza]

        const regex = /^\d+(\.\d{0,2})?$/;

        if (!value || (value && (regex.test(value) || value === ''))) {
            pizzasUpdate = pizzasUpdate.map(pizza => {
                if (pizza.code === code) {
                    return { ...pizza, price: value };
                } else {
                    return pizza
                }
            })
            setSizeSelectedPizza(pizzasUpdate)
        }
    }

    const handleChangeSizeCost = (value, code) => {
        let pizzasUpdate = [...sizeSelectedPizza]

        const regex = /^\d+(\.\d{0,2})?$/;

        if (!value || (value && (regex.test(value) || value === ''))) {
            pizzasUpdate = pizzasUpdate.map(pizza => {
                if (pizza.code === code) {
                    return { ...pizza, cost: value };
                } else {
                    return pizza
                }
            })
            setSizeSelectedPizza(pizzasUpdate)
        }
    }

    const handleSelectCompany = (val) => {
        setFormData(prevState => ({
            ...prevState,
            category_id: '',
            subcategory_id: '',
            ingredients: [],
            extras: [],
            drinks_ids: [],
            combos_ids: []

        }));
        getParamsUpdate(val)
    }

    const handleDecimalInput = (e,code = null) => {
        let rawValue = 0;
        const name = e.target.name;
        if (e.target.value != "") {
            rawValue = e.target.value.replace(/\D/g, ""); 
        }
        const decimalValue = (parseInt(rawValue, 10) / 100).toFixed(2);
        if (code) {
            if (name.includes('size_price')) {
                handleChangeSizePrice(decimalValue,code)
            }else{
                handleChangeSizeCost(decimalValue,code)
            }
        }else{
            updateFormData(name, decimalValue)
        }
    };

    const handleCheck = (e) => {
        let key = e.target.name;
        setFormData({
            ...formData,
            [key]: e.target.checked
        });
    }

    return (
        <div className="app container">
            <span className="material-symbols-outlined absolute" role="button" onClick={back}>arrow_back</span>
            <div className="title-section-products-forms" style={{ marginLeft: '50px' }}>
                {title}
                <div>
                    <p>
                        {description}
                    </p>
                </div>
            </div>

            <Stepper className="mb-4" activeStep={activeStep} alternativeLabel>
                <Step key="1" completed={completed[0]}>
                    <StepButton color="inherit" onClick={
                        () => {
                            handleStep(0);
                            completedStep(0, false);
                            setFormData({
                                ...formData,
                                product_drink: false,
                                product_combo: false,
                                product_view: "",
                                product_dessert: false,
                                product_pizza: false,
                                productpizza_id: null
                            });
                            setProductAssociateCombo(false);
                        }
                    }>
                        1
                    </StepButton>
                </Step>


                <Step key="2" completed={completed[1]}>
                    <StepButton color="inherit" onClick={
                        () => { handleStep(1) }}>
                        2
                    </StepButton>
                </Step>
                <Step key="3">
                    <StepButton color="inherit" onClick={
                        () => { handleStep(2) }}>
                        3
                    </StepButton>
                </Step>
                {
                    (locations.length === 1 && id === undefined) &&
                    <Step key="4">
                        <StepButton color="inherit" onClick={
                            () => { handleStep(3) }}>
                            4
                        </StepButton>
                    </Step>
                }
                <Step key="5">
                    <StepButton color="inherit" onClick={
                        () => { handleStep(4) }}>
                        { locations.length === 1 ? 5 : 4}
                    </StepButton>
                </Step>
            </Stepper>
            <>

                {
                    activeStep === 0 && formData.product_drink != true && formData.product_combo != true && formData.product_pizza != true &&
                    (
                        <>
                            <div className="container bold-container-content">
                                <center className="mb-4 mt-3">{t('productComponent.kindOfProduct')}</center>
                                <div className="row np-row">

                                    {/* Simple */}
                                    <div className="col-6 col-md-3">

                                        <div className={`card-option ${formData.product_view == 'NORMAL' && formData.product_dessert == false ? "selected" : ""}`} role="button"
                                            onClick={
                                                () => {
                                                    completedStep(0);
                                                    setFormData({ ...formData, product_view: "NORMAL", product_pizza: false, product_combo: false, product_dessert: false, product_drink: false, productpizza_id: null })
                                                    handleStep(1);
                                                }
                                            }>
                                            <div className="info-icon">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 24 24"
                                                    width="20"
                                                    height="20"
                                                    fill="currentColor"
                                                >
                                                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm1-13h-2v2h2V7zm0 4h-2v6h2v-6z" />
                                                </svg>
                                                <span className="tooltip">{t("productComponent.infoProductsSimple")}</span>
                                            </div>
                                            <div className="card-option-img-content">
                                                {modeTheme ? <img className="card-option-img" src={simplePng}></img>
                                                    : <img className="card-option-img" src={simpleImg}></img>}
                                            </div>
                                            <div className="card-option-content">
                                                <div className="card-option-title">
                                                    Simple
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* COMBO */}
                                    <div className="col-6 col-md-3">
                                        <div className={`card-option ${formData.product_view === "COMBO" ? "selected" : ""}`} role="button"
                                            onClick={
                                                () => {
                                                    setFormData({ ...formData, product_combo: true, product_view: "", product_drink: false, product_dessert: false });
                                                    setProductAssociateCombo(false);
                                                }
                                            }>
                                            <div className="info-icon">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 24 24"
                                                    width="20"
                                                    height="20"
                                                    fill="currentColor"
                                                >
                                                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm1-13h-2v2h2V7zm0 4h-2v6h2v-6z" />
                                                </svg>
                                                <span className="tooltip">{t("productComponent.infoProductsCombo")}</span>
                                            </div>
                                            <div className="card-option-img-content">
                                                {modeTheme ? <img className="card-option-img" src={combosPng}></img>
                                                    : <img className="card-option-img" src={combosImg}></img>}
                                            </div>

                                            <div className="card-option-content">
                                                <div className="card-option-title">
                                                    Combos
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Pizza */}

                                    <div className="col-6 col-md-3">
                                        <div className={`card-option ${formData.product_view == 'PIZZA' ? "selected" : ""}`} role="button"
                                            onClick={
                                                () => {
                                                    completedStep(0);
                                                    setFormData({ ...formData, product_view: "PIZZA", product_pizza: true, product_dessert: false, product_drink: false, product_combo: false })
                                                    handleStep(1);
                                                }
                                            }>
                                            <div className="info-icon">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 24 24"
                                                    width="20"
                                                    height="20"
                                                    fill="currentColor"
                                                >
                                                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm1-13h-2v2h2V7zm0 4h-2v6h2v-6z" />
                                                </svg>
                                                <span className="tooltip">{t("productComponent.infoProductsPizza")}</span>
                                            </div>
                                            <div className="card-option-img-content">
                                                {modeTheme ? <img className="card-option-img" src={pizzaPng}></img>
                                                    : <img className="card-option-img" src={pizzaImg}></img>}
                                            </div>
                                            <div className="card-option-content">
                                                <div className="card-option-title">
                                                    Pizza
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* drinks */}
                                    <div className="col-6 col-md-3">
                                        <div className={`card-option ${formData.product_view == "MODAL_DRINK_SIMPLE"
                                            || formData.product_view == "MODAL_DRINK_SEMICUSTOM"
                                            || formData.product_view == "MODAL_DRINK_CUSTOM" ? "selected" : ""}`}
                                            role="button" onClick={
                                                () => {
                                                    completedStep(0);
                                                    setFormData({ ...formData, product_drink: true, product_pizza: false, product_combo: false, product_dessert: false, productpizza_id: null })
                                                }
                                            }>
                                            <div className="info-icon">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 24 24"
                                                    width="20"
                                                    height="20"
                                                    fill="currentColor"
                                                >
                                                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm1-13h-2v2h2V7zm0 4h-2v6h2v-6z" />
                                                </svg>
                                                <span className="tooltip">{t("productComponent.infoProductsDrink")}</span>
                                            </div>
                                            <div className="card-option-img-content">
                                                {modeTheme ? <img className="card-option-img" src={drinkPng}></img>
                                                    : <img className="card-option-img" src={drinkImg}></img>}
                                            </div>
                                            <div className="card-option-content">
                                                <div className="card-option-title">
                                                    {t('productComponent.drink')}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* desserts */}

                                    <div className="col-6 col-md-3">
                                        <div className={`card-option ${formData.product_dessert == true && formData.product_view == "NORMAL" ? "selected" : ""}`} role="button" onClick={
                                            () => {
                                                completedStep(0);
                                                setFormData({ ...formData, product_view: "NORMAL", product_dessert: true, product_drink: false, product_pizza: false, product_combo: false, productpizza_id: null })
                                                handleStep(1);
                                            }
                                        }>
                                            <div className="info-icon">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 24 24"
                                                    width="20"
                                                    height="20"
                                                    fill="currentColor"
                                                >
                                                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm1-13h-2v2h2V7zm0 4h-2v6h2v-6z" />
                                                </svg>
                                                <span className="tooltip">{t("productComponent.infoProductsDessert")}</span>
                                            </div>
                                            <div className="card-option-img-content">
                                                {modeTheme ? <img className="card-option-img" src={dessertPng}></img>
                                                    : <img className="card-option-img" src={dessertImg}></img>}
                                            </div>
                                            <div className="card-option-content">
                                                <div className="card-option-title">
                                                    {t('productComponent.dessert')}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>

                    )
                }

                {
                    activeStep === 0 && formData.product_drink == true && 
                    (
                        <>
                            <div className="container bold-container-content">
                                <center className="mb-4 mt-3">{t('productComponent.drinkPresentation')}</center>
                                <div className="row np-row flex justify-content-center mb-4">
                                    <div className="col-6 col-md-3">
                                        <div className={`card-option ${formData.product_view == 'MODAL_DRINK_SIMPLE' ? "selected" : ""} `} role="button" onClick={
                                            () => {
                                                setFormData({ ...formData, product_view: "MODAL_DRINK_SIMPLE" })
                                                handleStep(1);
                                            }
                                        }>
                                            <div className="info-icon">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 24 24"
                                                    width="20"
                                                    height="20"
                                                    fill="currentColor"
                                                >
                                                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm1-13h-2v2h2V7zm0 4h-2v6h2v-6z" />
                                                </svg>
                                                <span className="tooltip">{t("productComponent.infoDrinkSimple")}</span>
                                            </div>
                                            <div className="card-option-img-content">
                                                {modeTheme ? <img className="card-option-img" src={modalSimplePng}></img>
                                                    : <img className="card-option-img" src={modalSimple}></img>}
                                            </div>
                                            <div className="card-option-content">
                                                <div className="card-option-title">
                                                    Simple
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-3">
                                        <div className={`card-option ${formData.product_view == 'MODAL_DRINK_CUSTOM' ? "selected" : ""}`} role="button" onClick={
                                            () => {
                                                completedStep(0);
                                                setFormData({ ...formData, product_view: "MODAL_DRINK_CUSTOM" });
                                                handleStep(1);
                                            }}>
                                            <div className="info-icon">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 24 24"
                                                    width="20"
                                                    height="20"
                                                    fill="currentColor"
                                                >
                                                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm1-13h-2v2h2V7zm0 4h-2v6h2v-6z" />
                                                </svg>
                                                <span className="tooltip">{t("productComponent.infoDrinkCustomizable")}</span>
                                            </div>
                                            <div className="card-option-img-content">
                                                {modeTheme ? <img className="card-option-img" src={modalCustomPng}></img>
                                                    : <img className="card-option-img" src={modalCustom}></img>}
                                            </div>
                                            <div className="card-option-content">
                                                <div className="card-option-title">
                                                    {t('productComponent.customizable')}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-center mt-4">
                                    <button className="bold-btn bold-btn-light" onClick={
                                        () => {
                                            handleStep(0);
                                            setFormData({ ...formData, product_drink: false })
                                        }
                                    }
                                    >{t('back')}</button>
                                </div>
                            </div>
                        </>
                    )
                }

                {
                    activeStep === 0 && formData.product_combo == true && formData.product_pizza == false && (formData.product_view === "" || formData.product_view == "COMBO" || formData.product_view == "NORMAL" || formData.product_view == "PIZZA") && 
                    (
                        <>
                            <div className="container bold-container-content">
                                <center className="mb-4 mt-3">{t('productComponent.productType')}</center>
                                <div className="row np-row flex justify-content-center mb-4">
                                    <div className="col-6 col-md-3">
                                        <div className={`card-option ${formData.product_view == "NORMAL" ? "selected" : ""}`} role="button"
                                            onClick={
                                                () => {
                                                    completedStep(0);
                                                    setFormData({ ...formData, product_view: "NORMAL", productpizza_id: "" });
                                                    handleStep(1);
                                                }
                                            }>
                                            <div className="info-icon">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 24 24"
                                                    width="20"
                                                    height="20"
                                                    fill="currentColor"
                                                >
                                                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm1-13h-2v2h2V7zm0 4h-2v6h2v-6z" />
                                                </svg>
                                                <span className="tooltip">{t("productComponent.infoComboSimple")}</span>
                                            </div>
                                            <div className="card-option-img-content">
                                                {modeTheme ? <img className="card-option-img" src={simplePng}></img>
                                                    : <img className="card-option-img" src={simpleImg}></img>}
                                            </div>
                                            <div className="card-option-content">
                                                <div className="card-option-title">
                                                    Simple
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-3">
                                        <div className={`card-option ${formData.product_view == "PIZZA" ? "selected" : ""}`} role="button"
                                            onClick={
                                                () => {
                                                    completedStep(0);
                                                    setFormData({ ...formData, product_view: "PIZZA", product_pizza: true })
                                                    handleStep(1);
                                                }
                                            }>
                                            <div className="info-icon">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 24 24"
                                                    width="20"
                                                    height="20"
                                                    fill="currentColor"
                                                >
                                                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm1-13h-2v2h2V7zm0 4h-2v6h2v-6z" />
                                                </svg>
                                                <span className="tooltip">{t("productComponent.infoComboPizza")}</span>
                                            </div>
                                            <div className="card-option-img-content">
                                                {modeTheme ? <img className="card-option-img" src={pizzaPng}></img>
                                                    : <img className="card-option-img" src={pizzaImg}></img>}
                                            </div>
                                            <div className="card-option-content">
                                                <div className="card-option-title">
                                                    Pizza
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-center mt-4">
                                    <button className="bold-btn bold-btn-light" onClick={
                                        () => {
                                            handleStep(0);
                                            setFormData({ ...formData, product_view: "COMBO", product_combo: false })
                                        }
                                    }
                                    >{t('back')}</button>
                                </div>
                            </div>
                        </>
                    )
                }

                {
                    activeStep === 1 &&  !isPizza &&
                    (
                        <div className="container bold-container-content">
                            <form onSubmit={handleSubmit(handleValidationSubmitStep2)} noValidate onKeyDown={handleKeyDow}>
                                <div className="container row mb-4">
                                    <div className="form-title col-md-12">
                                        {t('productComponent.infoGeneral')}
                                    </div>
                                    {profile <= 2 &&(
                                        <div className="col-md-6 mb-2">
                                            <BoldSelectValidated
                                                title={t('companyComponent.company') + " *"}
                                                value={formData.company_id ?? ''}
                                                name="company_id"
                                                options={companiesOptions}
                                                onChange={handleForm}
                                                register={register}
                                                errors={errors}
                                                required={true}
                                                dependency={handleSelectCompany}
                                                disable={disableSelectCompany}
                                            />
                                        </div>
                                    )}
                                    
                                    <div className="col-md-6 mb-2">
                                        <TextField
                                            label={t("name") + " *"}
                                            variant="standard"
                                            size="small"
                                            {...register("product_name", { required: true, maxLength: 80 })}
                                            value={formData.product_name ?? ''}
                                            onChange={handleForm}
                                            error={errors.product_name != null}
                                            helperText={
                                                errors.product_name?.type === 'required' ? t('validationErrorMessages.required') :
                                                    errors.product_name?.type === 'maxLength' ? `${t('validationErrorMessages.long')} 80  ${t('validationErrorMessages.longSuffix')}` : ""
                                            }
                                        />
                                    </div>
                                    {formData.product_view !== "PIZZA" &&
                                        <div className="col-md-6 mb-2">
                                            <TextField
                                                label={t('productComponent.group') + " (" + t('optional') + ")"}
                                                variant="standard"
                                                size="small"
                                                {...register("product_group", { maxLength: 60 })}
                                                value={formData.product_group ?? ''}
                                                onChange={handleForm}
                                                error={errors.product_group != null}
                                                helperText={
                                                    errors.product_group?.type === 'maxLength' ? `${t('validationErrorMessages.long')} 60  ${t('validationErrorMessages.longSuffix')}` : ""
                                                }
                                            />
                                            <div style={{ color: "rgb(123, 128, 154)", fontSize: "11px", marginTop: "-1.5vh", marginBottom: "1.5vh" }}>{t('productComponent.groupAdvice')}</div>
                                        </div>
                                    }


                                    <div className="col-md-6 mb-2">
                                        <TextField
                                            label={'SKU' + " (" + t('optional') + ")"}
                                            variant="standard"
                                            size="small"
                                            {...register("product_sku", { maxLength: 40 })}
                                            value={formData.product_sku ?? ''}
                                            onChange={handleForm}
                                            error={errors.product_sku != null}
                                            helperText={
                                                errors.product_sku?.type === 'maxLength' ? `${t('validationErrorMessages.long')}40  ${t('validationErrorMessages.longSuffix')}` : ""
                                            }
                                        />
                                    </div>
                                    {formData.product_view !== "PIZZA" &&
                                        <>
                                            <div className="col-md-6 mb-2">
                                                <TextField
                                                    label={t('productComponent.price') + " *"}
                                                    variant="standard"
                                                    size="small"
                                                    {...register("product_price", {
                                                        required: true,
                                                        min: 0,
                                                        max: 2147483647
                                                    })}
                                                    value={formData.product_price ?? ''}
                                                    onChange={handleDecimalInput}
                                                    error={errors.product_price != null}
                                                    type="number"
                                                    multiline={true}
                                                    InputProps={{
                                                        inputProps: {
                                                            min: 0,
                                                            max: 2147483647
                                                        },
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <AttachMoneyIcon />

                                                            </InputAdornment>
                                                        )
                                                    }}
                                                    helperText={
                                                        errors.product_price?.type === 'required'
                                                            ? t('validationErrorMessages.required')
                                                            : errors.product_price?.type === 'min'
                                                                ? `${t('validationErrorMessages.minNumber')} 0 `
                                                                : errors.product_price?.type === 'max'
                                                                    ? `${t('validationErrorMessages.MaxNumber')} 2147483647 `
                                                                    : ""
                                                    }
                                                />
                                            </div>

                                            <div className="col-md-6 mb-2">
                                                <TextField
                                                    label={t('productComponent.cost')}
                                                    variant="standard"
                                                    size="small"
                                                    {...register("product_cost", {
                                                        min: 0,
                                                        max: 2147483647
                                                    })}
                                                    value={formData.product_cost ?? ''}
                                                    onChange={handleDecimalInput}
                                                    error={errors.product_cost != null}
                                                    type="number"
                                                    multiline={true}
                                                    InputProps={{
                                                        inputProps: {
                                                            min: 0,
                                                            max: 2147483647
                                                        },
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <AttachMoneyIcon />

                                                            </InputAdornment>
                                                        )
                                                    }}
                                                    helperText={
                                                        errors.product_cost?.type === 'min'
                                                            ? `${t('validationErrorMessages.minNumber')} 0 `
                                                            : errors.product_cost?.type === 'max'
                                                                ? `${t('validationErrorMessages.MaxNumber')} 2147483647 `
                                                                : ""
                                                    }
                                                />
                                            </div>
                                        </>
                                    }


                                    <div className="col-md-6 mb-2">

                                        <BoldSelectValidated
                                            title={t("categoryComponent.category")}
                                            value={formData.category_id ?? ''}
                                            name="category_id"
                                            onChange={handleForm}
                                            options={categoriesList}
                                            register={register}
                                            errors={errors}
                                            dependency={handleCategory}
                                        />
                                    </div>

                                    <div className="col-md-6 mb-2">
                                        <BoldSelect title={t("subcategoryComponent.subcategory")} 
                                            value={formData.subcategory_id ?? ''} 
                                            name="subcategory_id" 
                                            options={subcategoriesList} 
                                            onChange={handleForm} 
                                            dependency={handleSubcategory}
                                        />

                                    </div>
                                </div>

                                <div className="d-flex justify-content-center mt-4">
                                    <button className="bold-btn bold-btn-light" onClick={(e) => {
                                        e.preventDefault();
                                        if (formData.product_combo) {
                                            setFormData({ ...formData, product_view: "COMBO",product_pizza: false });
                                        }else{
                                            setFormData({
                                                ...formData,
                                                product_pizza: false,
                                                product_combo: false
                                            });

                                        }
                                        handleStep(0);
                                    }}>{t('back')}</button>

                                    <button className="bold-btn bold-btn-dark" type="submit"

                                    >{t('next')}</button>
                                </div>
                            </form>
                        </div>
                    )
                }

                {
                    activeStep === 1 && formData.product_view === "PIZZA" && formData.product_pizza == true && formData.product_combo != true && isPizza &&
                    (
                        <>
                            {
                                id !== undefined ?
                                    <>
                                        {sizePrices === false ? (
                                            <div className="container bold-container-content">
                                                <center className="mb-4 mt-3">{t('productComponent.sizePizza')}</center>
                                                <div className="row np-row flex justify-content-center mb-4">
                                                    {pizzasConfiguration.length === 0 ? (
                                                        <>
                                                            <div className="row np-row flex justify-content-center mb-4">
                                                                {t("productComponent.sizeconfig")}
                                                            </div>
                                                            <div className="d-flex justify-content-center">
                                                                {modeTheme ?
                                                                    <img className="col-6" src={darkEmptyPizza} alt="SelectLocationDark"/>
                                                                    :
                                                                    <img className="col-6" src={lightEmptyPizza} alt="SelectLocationLight"/>
                                                                }
                                                            </div>
                                                            <div className="d-flex justify-content-center mt-4">
                                                                <button className="col-3 bold-btn bold-btn-dark" 
                                                                    onClick={
                                                                        () => nav('/configuration/pizzaconfiguration')
                                                                    }>
                                                                    {t('pizzaConfigurationComponent.addSizes')}
                                                                </button>
                                                            </div>
                                                        </>
                                                    ): (
                                                        pizzasConfiguration.map(item => (
                                                            <div className="col-6 col-md-3" key={item.code}>
                                                                <div
                                                                    className={`card-option ${item.code === formData.productpizza_id ? "selected" : ""}`}
                                                                    role="button"
                                                                    onClick={(e) => {
                                                                        setSizePrices(true);
                                                                        setFormData({ ...formData, productpizza_id: item.code });
                                                                        selectSizePizza(e, item);
                                                                        setSelectedSize(item.name)
    
                                                                    }}
                                                                >
                                                                    <div className="card-option-img-content">
                                                                        {modeTheme ? (
                                                                            <img className="card-option-img" src={pizzaSiluetaPng} alt="Pizza" />
                                                                        ) : (
                                                                            <img className="card-option-img" src={pizzaSilueta} alt="Pizza" />
                                                                        )}
                                                                    </div>
                                                                    <div className="card-option-content">
                                                                        <div className="card-option-title">
                                                                            {item.name}
                                                                            {item.diametter && (
                                                                                <span className="text-gray light small-text d-block">
                                                                                    {t("pizzaConfigurationComponent.diametter")}: {item.diametter}
                                                                                </span>
                                                                            )}
                                                                            {item.slices && (
                                                                                <span className="text-gray light small-text d-block">
                                                                                    {t("pizzaConfigurationComponent.slices")}: {item.slices}
                                                                                </span>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))

                                                    )}
                                                </div>
                                                <div className="d-flex justify-content-center mt-4">
                                                    <button
                                                        className="bold-btn bold-btn-light"
                                                        onClick={() => {
                                                            if (formData.product_combo) {
                                                                setFormData({
                                                                    ...formData,
                                                                    product_view: "COMBO",
                                                                });
                                                            }
                                                            setIsPizza(false);
                                                        }}
                                                    >
                                                        {t('back')}
                                                    </button>
                                                </div>
                                            </div>
                                        ) : (
                                            <form
                                                className="container bold-container-content"
                                                onSubmit={handleSubmit(() => {
                                                    completedStep(1);
                                                    handleStep(2);
                                                })}
                                            >
                                                <center className="mb-4 mt-3">{t('productComponent.sizePizzaPrice')}</center>
                                                <div className="column np-column flex justify-content-center mb-4">
                                                    <div className="container row mb-4" key={formData.productpizza_id}>
                                                        <div className="card-option-title">{selectedSize}:</div>
                                                        <div className="col-md-6 mb-2">
                                                            <TextField
                                                                label={t('productComponent.price') + " *"}
                                                                variant="standard"
                                                                size="small"
                                                                {...register('product_price', {
                                                                    required: true,
                                                                    maxLength: 20,
                                                                })}
                                                                value={formData.product_price ?? ''}
                                                                onChange={handleDecimalInput}
                                                                type="number"
                                                                multiline
                                                                InputProps={{
                                                                    inputProps: {
                                                                        min: 0,
                                                                        max: 2147483647,
                                                                    },
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            <AttachMoneyIcon />
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                                error={errors.size_price != null}
                                                                helperText={
                                                                    errors.size_price?.type === 'required'
                                                                        ? t('validationErrorMessages.required')
                                                                        : errors.size_price?.type === 'maxLength'
                                                                            ? `${t('validationErrorMessages.long')} 20 ${t('validationErrorMessages.longSuffix')}`
                                                                            : ""
                                                                }
                                                            />
                                                        </div>
                                                        <div className="col-md-6 mb-2">
                                                            <TextField
                                                                label={t('productComponent.cost')}
                                                                variant="standard"
                                                                size="small"
                                                                {...register('product_cost', {
                                                                    required: true,
                                                                    maxLength: 20,
                                                                })}
                                                                value={formData.product_cost ?? ''}
                                                                onChange={handleDecimalInput}
                                                                type="number"
                                                                multiline
                                                                InputProps={{
                                                                    inputProps: {
                                                                        min: 0,
                                                                        max: 2147483647,
                                                                    },
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            <AttachMoneyIcon />
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                                error={errors.product_cost != null}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-center mt-4">
                                                    <button
                                                        className="bold-btn bold-btn-light"
                                                        onClick={() => setSizePrices(false)}
                                                    >
                                                        {t('back')}
                                                    </button>
                                                    <button className="bold-btn bold-btn-dark" type="submit">
                                                        {t('next')}
                                                    </button>
                                                </div>
                                            </form>
                                        )}
                                    </>
                                    :
                                    <>
                                        {sizePrices === false ?
                                            <>
                                                <div className="container bold-container-content">
                                                    <div className="row np-row flex justify-content-center mb-5 mt-4">
                                                        {pizzasConfiguration.length == 0 ? 
                                                        <>
                                                            <div className="row np-row flex justify-content-center mb-4">
                                                                {t("productComponent.sizeconfig")}
                                                            </div>
                                                            <div className="d-flex justify-content-center">
                                                                {modeTheme ?
                                                                    <img className="col-6" src={darkEmptyPizza} alt="SelectLocationDark"/>
                                                                    :
                                                                    <img className="col-6" src={lightEmptyPizza} alt="SelectLocationLight"/>
                                                                }
                                                            </div>
                                                            <div className="d-flex justify-content-center mt-4">
                                                                <button className="col-3 bold-btn bold-btn-dark" 
                                                                    onClick={
                                                                        () => nav('/configuration/pizzaconfiguration')
                                                                    }>
                                                                    {t('pizzaConfigurationComponent.addSizes')}
                                                                </button>
                                                            </div>
                                                        </>
                                                        :
                                                        <>
                                                            <center className="mb-5 mt-3">{t('productComponent.sizePizza')}</center>
                                                            {pizzasConfiguration.map(item => {
                                                                return (
                                                                    <div className="col-6 col-md-3" key={item.code}>
                                                                        <div className={`card-option ${item.selected ? "selected" : ""}`} role="button"
                                                                            onClick={
                                                                                (e) => {
                                                                                    selectSizePizza(e, item)
                                                                                }
                                                                            }>
                                                                            <div className="card-option-img-content">
                                                                                {modeTheme ? <img className="card-option-img" src={pizzaSiluetaPng}></img>
                                                                                    : <img className="card-option-img" src={pizzaSilueta}></img>}
                                                                            </div>
                                                                            <div className="card-option-content">
                                                                                <div className="card-option-title">
                                                                                    {item.name}<br />
                                                                                    {item.diametter && (
                                                                                        <span className="text-gray light small-text d-block">
                                                                                            {t("pizzaConfigurationComponent.diametter")}: {item.diametter}
                                                                                        </span>
                                                                                    )}
                                                                                    {item.slices && (
                                                                                        <span className="text-gray light small-text d-block">
                                                                                            {t("pizzaConfigurationComponent.slices")}: {item.slices}
                                                                                        </span>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}
                                                            <div className="d-flex justify-content-center mt-4">
                                                                <button className="bold-btn bold-btn-light" onClick={
                                                                    () => {
                                                                        if (formData.product_combo) {
                                                                            setFormData({ ...formData, product_view: "COMBO" });
                                                                        }
                                                                        setIsPizza(false)
                                                                    }
                                                                }>{t('back')}</button>
                                                                <button className="bold-btn bold-btn-dark" disabled={sizeSelected.length < 1} onClick={
                                                                    () => {
                                                                        filterSizes()
                                                                        setSizePrices(true)
                                                                    }}>{t('next')}</button>
                                                            </div>
                                                        </>
                                                        }
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <form className="container bold-container-content" onSubmit={handleSubmit(() => {
                                                    completedStep(1);
                                                    handleStep(2);
                                                })}>
                                                    <center className="mb-4 mt-3">{t('productComponent.sizePizzaPrice')}</center>
                                                    <div className="column np-column flex justify-content-center mb-4">
                                                        {sizeSelectedPizza.map((item, index) => {
                                                            return (
                                                                <div className="container row mb-4" key={item.code}>
                                                                    <div className="card-option-title">
                                                                        {item.name}:
                                                                    </div>
                                                                    <div className="col-md-6 mb-2">
                                                                        <TextField
                                                                            label={t('productComponent.price') + " *"} 
                                                                            variant="standard" 
                                                                            size="small"
                                                                            {...register(`size_price_${index}`, { required: true, maxLength: 20 })}
                                                                            value={sizeSelectedPizza[index].price}
                                                                            onChange={(e) => handleDecimalInput(e, item.code)}
                                                                            type="number"
                                                                            multiline={true}
                                                                            InputProps={{
                                                                                inputProps: {
                                                                                    min: 0,
                                                                                    max: 2147483647
                                                                                },
                                                                                startAdornment: (
                                                                                    <InputAdornment position="start">
                                                                                        <AttachMoneyIcon />

                                                                                    </InputAdornment>
                                                                                )
                                                                            }}
                                                                            error={errors[`size_price_${index}`] != null}
                                                                            helperText={
                                                                                errors[`size_price_${index}`]?.type === 'required' ? t('validationErrorMessages.required') :
                                                                                    errors[`size_price_${index}`]?.type === 'maxLength' ? `${t('validationErrorMessages.long')} 20  ${t('validationErrorMessages.longSuffix')}` : ""
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <div className="col-md-6 mb-2">
                                                                        <TextField
                                                                            label={t('productComponent.cost')} variant="standard" size="small"
                                                                            value={sizeSelectedPizza[index].cost}
                                                                            onChange={(e) => handleDecimalInput(e, item.code)}
                                                                            error={errors.size_cost != null}
                                                                            type="number"
                                                                            multiline={true}
                                                                            InputProps={{
                                                                                inputProps: {
                                                                                    min: 0,
                                                                                    max: 2147483647
                                                                                },
                                                                                startAdornment: (
                                                                                    <InputAdornment position="start">
                                                                                        <AttachMoneyIcon />

                                                                                    </InputAdornment>
                                                                                )
                                                                            }}

                                                                        />
                                                                    </div>
                                                                    <br />
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                    <div className="d-flex justify-content-center mt-4">
                                                        <button className="bold-btn bold-btn-light" onClick={
                                                            () => {
                                                                setSizePrices(false)
                                                            }
                                                        }>{t('back')}</button>
                                                        <button className="bold-btn bold-btn-dark" type="submit">{t('next')}</button>
                                                    </div>
                                                </form>
                                            </>
                                        }
                                    </>
                            }

                        </>
                    )
                }

                {
                    activeStep === 1 && formData.product_view === "PIZZA" && formData.product_pizza == true && formData.product_combo == true && isPizza &&
                    (
                        <>
                            {
                                id !== undefined ?
                                    <>
                                        {sizePrices === false ? (
                                            <div className="container bold-container-content">
                                                <center className="mb-4 mt-3">{t('productComponent.sizePizza')}</center>
                                                <div className="row np-row flex justify-content-center mb-4">
                                                    {pizzasConfiguration.length === 0 && (
                                                        <div className="row np-row flex justify-content-center mb-4">
                                                            {t("productComponent.sizeconfig")}
                                                        </div>
                                                    )}
                                                    {pizzasConfiguration.map(item => (
                                                        <div className="col-6 col-md-3" key={item.code}>
                                                            <div
                                                                className={`card-option ${item.code === formData.productpizza_id ? "selected" : ""}`}
                                                                role="button"
                                                                onClick={(e) => {
                                                                    setSizePrices(true);
                                                                    setFormData({ ...formData, productpizza_id: item.code });
                                                                    selectSizePizza(e, item);
                                                                    setSelectedSize(item.name)

                                                                }}
                                                            >
                                                                <div className="card-option-img-content">
                                                                    {modeTheme ? (
                                                                        <img className="card-option-img" src={pizzaSiluetaPng} alt="Pizza" />
                                                                    ) : (
                                                                        <img className="card-option-img" src={pizzaSilueta} alt="Pizza" />
                                                                    )}
                                                                </div>
                                                                <div className="card-option-content">
                                                                    <div className="card-option-title">
                                                                        {item.name}
                                                                        {item.diametter && (
                                                                            <span className="text-gray light small-text d-block">
                                                                                {t("pizzaConfigurationComponent.diametter")}: {item.diametter}
                                                                            </span>
                                                                        )}
                                                                        {item.slices && (
                                                                            <span className="text-gray light small-text d-block">
                                                                                {t("pizzaConfigurationComponent.slices")}: {item.slices}
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                                <div className="d-flex justify-content-center mt-4">
                                                    <button
                                                        className="bold-btn bold-btn-light"
                                                        onClick={() => {
                                                            if (formData.product_combo) {
                                                                setFormData({
                                                                    ...formData,
                                                                    product_view: "PIZZA",
                                                                });
                                                            }
                                                            setIsPizza(false);
                                                        }}
                                                    >
                                                        {t('back')}
                                                    </button>
                                                </div>
                                            </div>
                                        ) : (
                                            <form
                                                className="container bold-container-content"
                                                onSubmit={handleSubmit(() => {
                                                    completedStep(1);
                                                    handleStep(2);
                                                })}
                                            >
                                                <center className="mb-4 mt-3">{t('productComponent.sizePizzaPrice')}</center>
                                                <div className="column np-column flex justify-content-center mb-4">
                                                    <div className="container row mb-4" key={formData.productpizza_id}>
                                                        <div className="card-option-title">{selectedSize}:</div>
                                                            <div className="col-md-6 mb-2">
                                                                <TextField
                                                                    label={t('productComponent.price') + " *"}
                                                                    variant="standard"
                                                                    size="small"
                                                                    {...register('product_price', {
                                                                        required: true,
                                                                        maxLength: 20,
                                                                    })}
                                                                    value={formData.product_price ?? ''}
                                                                    onChange={(e) => handleDecimalInput(e)}
                                                                    type="number"
                                                                    multiline
                                                                    InputProps={{
                                                                        inputProps: {
                                                                            min: 0,
                                                                            max: 2147483647,
                                                                        },
                                                                        startAdornment: (
                                                                            <InputAdornment position="start">
                                                                                <AttachMoneyIcon />
                                                                            </InputAdornment>
                                                                        ),
                                                                    }}
                                                                    error={errors.product_price != null}
                                                                    helperText={
                                                                        errors.product_price?.type === 'required'
                                                                            ? t('validationErrorMessages.required')
                                                                            : errors.product_price?.type === 'maxLength'
                                                                                ? `${t('validationErrorMessages.long')} 20 ${t('validationErrorMessages.longSuffix')}`
                                                                                : ""
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="col-md-6 mb-2">
                                                                <TextField
                                                                    label={t('productComponent.cost')}
                                                                    variant="standard"
                                                                    size="small"
                                                                    {...register('product_cost', {
                                                                        maxLength: 20,
                                                                    })}
                                                                    value={formData.product_cost ?? ''}
                                                                    onChange={(e) => handleDecimalInput(e)}
                                                                    type="number"
                                                                    multiline
                                                                    InputProps={{
                                                                        inputProps: {
                                                                            min: 0,
                                                                            max: 2147483647,
                                                                        },
                                                                        startAdornment: (
                                                                            <InputAdornment position="start">
                                                                                <AttachMoneyIcon />
                                                                            </InputAdornment>
                                                                        ),
                                                                    }}
                                                                    error={errors.product_cost != null}
                                                                />
                                                            </div>
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-center mt-4">
                                                    <button
                                                        className="bold-btn bold-btn-light"
                                                        onClick={() => setSizePrices(false)}
                                                    >
                                                        {t('back')}
                                                    </button>
                                                    <button className="bold-btn bold-btn-dark" type="submit">
                                                        {t('next')}
                                                    </button>
                                                </div>
                                            </form>
                                        )}
                                    </>
                                    :
                                    <>
                                        {sizePrices === false ?
                                            <>
                                                <div className="container bold-container-content">
                                                    <center className="mb-4 mt-3">{t('productComponent.sizePizza')}</center>
                                                    <div className="row np-row flex justify-content-center mb-4">
                                                        {
                                                            pizzasConfiguration.length == 0 && <div className="row np-row flex justify-content-center mb-4">{t("productComponent.sizeconfig")}</div>
                                                        }
                                                        {pizzasConfiguration.map(item => {
                                                            return (
                                                                <div className="col-6 col-md-3" key={item.code}>
                                                                    <div className={`card-option ${item.selected ? "selected" : ""}`} role="button"
                                                                        onClick={
                                                                            (e) => {
                                                                                selectSizePizza(e, item)
                                                                            }
                                                                        }>
                                                                        <div className="card-option-img-content">
                                                                            {modeTheme ? <img className="card-option-img" src={pizzaSiluetaPng}></img>
                                                                                : <img className="card-option-img" src={pizzaSilueta}></img>}
                                                                        </div>
                                                                        <div className="card-option-content">
                                                                            <div className="card-option-title">
                                                                                {item.name}<br />
                                                                                {item.diametter && (
                                                                                    <span className="text-gray light small-text d-block">
                                                                                        {t("pizzaConfigurationComponent.diametter")}: {item.diametter}
                                                                                    </span>
                                                                                )}
                                                                                {item.slices && (
                                                                                    <span className="text-gray light small-text d-block">
                                                                                        {t("pizzaConfigurationComponent.slices")}: {item.slices}
                                                                                    </span>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                    <div className="d-flex justify-content-center mt-4">
                                                        <button className="bold-btn bold-btn-light" onClick={
                                                            () => {
                                                                if (formData.product_combo) {
                                                                    if (formData.product_pizza === true) {
                                                                        setFormData({ ...formData, product_view: "PIZZA",product_pizza: true });
                                                                    }else {
                                                                        setFormData({ ...formData, product_view: "COMBO",product_pizza: false });
                                                                    }
                                                                }
                                                                setIsPizza(false)
                                                            }
                                                        }>{t('back')}</button>
                                                        <button className="bold-btn bold-btn-dark" disabled={sizeSelected.length < 1} onClick={
                                                            () => {
                                                                filterSizes()
                                                                setSizePrices(true)
                                                            }}>{t('next')}</button>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <form className="container bold-container-content" onSubmit={handleSubmit(() => {
                                                    completedStep(1);
                                                    handleStep(2);
                                                })}>
                                                    <center className="mb-4 mt-3">{t('productComponent.sizePizzaPrice')}</center>
                                                    <div className="column np-column flex justify-content-center mb-4">
                                                        {sizeSelectedPizza.map((item, index) => {
                                                            return (
                                                                <div className="container row mb-4" key={item.code}>
                                                                    <div className="card-option-title">
                                                                        {item.name}:
                                                                    </div>
                                                                    <div className="col-md-6 mb-2">
                                                                        <TextField
                                                                            label={t('productComponent.price') + " *"} variant="standard" size="small"
                                                                            {...register(`size_price_${index}`, { required: true, maxLength: 20 })}
                                                                            value={sizeSelectedPizza[index].price}
                                                                            onChange={(e) => handleDecimalInput(e, item.code)}
                                                                            error={errors[`size_price_${index}`] != null}
                                                                            type="number"
                                                                            multiline={true}
                                                                            InputProps={{
                                                                                inputProps: {
                                                                                    min: 0,
                                                                                    max: 2147483647
                                                                                },
                                                                                startAdornment: (
                                                                                    <InputAdornment position="start">
                                                                                        <AttachMoneyIcon />

                                                                                    </InputAdornment>
                                                                                )
                                                                            }}
                                                                            helperText={
                                                                                errors[`size_price_${index}`]?.type === 'required' ? t('validationErrorMessages.required') :
                                                                                    errors[`size_price_${index}`]?.type === 'maxLength' ? `${t('validationErrorMessages.long')} 20  ${t('validationErrorMessages.longSuffix')}` : ""
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <div className="col-md-6 mb-2">
                                                                        <TextField
                                                                            label={t('productComponent.cost')} variant="standard" size="small"
                                                                            value={sizeSelectedPizza[index].cost}
                                                                            onChange={(e) => handleDecimalInput(e,item.code)}
                                                                            error={errors.size_cost != null}
                                                                            type="number"
                                                                            multiline={true}
                                                                            InputProps={{
                                                                                inputProps: {
                                                                                    min: 0,
                                                                                    max: 2147483647
                                                                                },
                                                                                startAdornment: (
                                                                                    <InputAdornment position="start">
                                                                                        <AttachMoneyIcon />

                                                                                    </InputAdornment>
                                                                                )
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <br />
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                    <div className="d-flex justify-content-center mt-4">
                                                        <button className="bold-btn bold-btn-light" onClick={
                                                            () => {
                                                                setSizePrices(false)
                                                            }
                                                        }>{t('back')}</button>
                                                        <button className="bold-btn bold-btn-dark" type="submit">{t('next')}</button>
                                                    </div>
                                                </form>
                                            </>
                                        }
                                    </>
                            }

                        </>
                    )
                }

                {
                    activeStep === 2 &&
                    (
                        <div className="container bold-container-content">
                            <div className="container row">

                                <div className="form-title col-md-12">
                                    {t('productComponent.additionalInformation')}
                                </div>
                                    <>
                                        <div className="col-md-6 mb-2">
                                            <TextField className="bold-text-field-material" name="product_description" value={formData.product_description ?? ''} onChange={handleForm} multiline rows={3} label={t('Description')} size="small" />
                                        </div>

                                        <div className="col-md-6 mb-2">
                                            <TextField className="bold-text-field-material" name="product_short_description" value={formData.product_short_description ?? ''} onChange={handleForm} multiline rows={3} label={t('shortDescription')} size="small" />
                                        </div>
                                    </>
                                    
                                {
                                    formData.product_view !== "MODAL_DRINK_SIMPLE" && formData.product_view !== "MODAL_DRINK_SEMICUSTOM" && (
                                        <>
                                            <div className="col-md-6 mb-2">
                                                <BoldMultiplesSelect title={t("productComponent.ingredients") + " (" + t('optional') + ")"} name="ingredients" value={formData.ingredients ?? ''} onChange={handleForm} options={itemList} />
                                            </div>
                                            <div className="col-md-6 mb-2">
                                                <BoldMultiplesSelect title={"Extras" + " (" + t('optional') + ")"} name="extras" value={formData.extras ?? ''} onChange={handleForm} options={itemList} />
                                            </div>
                                        </>
                                    )
                                }
                                {
                                    formData.product_combo != true && formData.product_drink != true &&
                                    (
                                        <>
                                            <div className="form-title col-md-12">
                                                {t('productComponent.Associate_combos')}
                                            </div>
                                            <div className="col-md-6">
                                                <FormControlLabel control={<Checkbox name="product_asociar_combo" checked={productAssociateCombo} onChange={(e) => { setProductAssociateCombo(!productAssociateCombo) }} />} label={t('productComponent.associateCombo')} />
                                                {
                                                    productAssociateCombo &&
                                                    <BoldMultiplesSelect title="Combos" name="combos_ids" value={formData.combos_ids ?? ''} onChange={handleForm} options={comboList} />
                                                }
                                            </div>
                                            <div className="col-md-6">
                                                <span className="text-gray light small-text">{t('productComponent.associateComboExplain')}</span>
                                            </div>
                                        </>
                                    )
                                }
                                {
                                    formData.product_combo == true && (

                                        <div className="col-md-12 mb-2">
                                            <div className="form-title col-md-12">
                                                {t('productComponent.Drinks')}
                                            </div>
                                            <div className="row np-row">
                                                <div className="col-md-6">
                                                    <FormControlLabel control={<Checkbox name="product_combo" checked={productAsociateDrink} onChange={(e) => { setProductAsociateDrink(!productAsociateDrink) }} />} label={t('productComponent.associateDrink')} />
                                                    {
                                                        productAsociateDrink &&
                                                        <BoldMultiplesSelect title={t('productComponent.Drinks')} name="drinks_ids" value={formData.drinks_ids ?? ''} onChange={handleForm} options={drinkList} />
                                                    }
                                                </div>
                                                <div className="col-md-6">
                                                    <span className="text-gray light small-text">{t('productComponent.associateDrinksExplain')}.</span>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>

                            <div className="d-flex justify-content-center mt-4">
                                <button className="bold-btn bold-btn-light" onClick={
                                    () => {
                                        handleStep(1);
                                    }
                                }
                                >{t('back')}</button>
                                <button className="bold-btn bold-btn-dark" onClick={(e) => {
                                    e.preventDefault();
                                    handleStep(locations.length === 1 && id === undefined ? 3 : 4);
                                }}>{t('next')}</button>
                            </div>
                        </div>
                    )
                }
                
                {
                    activeStep === 3 && id === undefined && locations.length === 1 &&
                    (
                        <div className="container bold-container-content">
                            <div className="container row">
                                <div className="section-forms">
                                    <div className="row">
                                        <div className="col-md-6 mb-2">
                                            <BoldSelectValidated
                                                title={t('printersComponent.printer')+ " (" + t('optional') + ")"}
                                                value={formData.printer_id ?? ''}
                                                name="printer_id"
                                                onChange={handleForm}
                                                options={printers}
                                                register={register}
                                                errors={errors}
                                                required={true}
                                            />
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <BoldMultiplesSelect
                                                title={t("printersLocations.printerAssociated") + " (" + t('optional') + ")"} 
                                                name="productlocation_printers" 
                                                value={formData.productlocation_printers ?? ''} 
                                                onChange={handleForm} 
                                                options={printersLocations} 
                                            />
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <BoldSelectValidated
                                                title={t('taxesComponent.taxgroup_name')}
                                                value={formData.taxgroup_id ?? ''}
                                                name="taxgroup_id"
                                                onChange={handleForm}
                                                options={taxgroup}
                                                register={register}
                                                errors={errors}
                                            />
                                        </div>
                                        {formData.productlocation_use_stock == 1 && (
                                            <>
                                                <div className="col-md-4 mb-2">
                                                    <TextField
                                                        label={t('productLocationComponent.stock')}
                                                        variant="standard"
                                                        size="small"
                                                        {...register("productlocation_stock", {
                                                            min: 0,
                                                            max: 2147483647
                                                        })}
                                                        value={formData.productlocation_stock ?? ''}
                                                        onChange={handleForm}
                                                        error={errors.productlocation_stock != null}
                                                        type="number"
                                                        multiline={true}
                                                        InputProps={{
                                                            inputProps: {
                                                                min: 0,
                                                                max: 2147483647
                                                            }
                                                        }}
                                                        helperText={
                                                            errors.productlocation_stock?.type === 'min'
                                                                ? `${t('validationErrorMessages.minNumber')} 0 `
                                                                : errors.productlocation_stock?.type === 'max'
                                                                    ? `${t('validationErrorMessages.MaxNumber')} 2147483647 `
                                                                    : ""
                                                        }
                                                    />

                                                </div>
                                                <div className="col-md-4 mb-2">

                                                    <TextField
                                                        label={t('productLocationComponent.min_stock')}
                                                        variant="standard"
                                                        size="small"
                                                        {...register("productlocation_min_stock", {
                                                            min: 0,
                                                            max: 2147483647
                                                        })}
                                                        value={formData.productlocation_min_stock ?? ''}
                                                        onChange={handleForm}
                                                        error={errors.productlocation_min_stock != null}
                                                        type="number"
                                                        multiline={true}
                                                        InputProps={{
                                                            inputProps: {
                                                                min: 0,
                                                                max: 2147483647
                                                            }
                                                        }}
                                                        helperText={
                                                            errors.productlocation_min_stock?.type === 'min'
                                                                ? `${t('validationErrorMessages.minNumber')} 0 `
                                                                : errors.productlocation_min_stock?.type === 'max'
                                                                    ? `${t('validationErrorMessages.MaxNumber')} 2147483647 `
                                                                    : ""
                                                        }
                                                    />
                                                </div>
                                                <div className="col-md-4 mb-2">
                                                    <TextField
                                                        label={t('productLocationComponent.max_stock')}
                                                        variant="standard"
                                                        size="small"
                                                        {...register("productlocation_max_stock", {
                                                            min: 0,
                                                            max: 2147483647
                                                        })}
                                                        value={formData.productlocation_max_stock ?? ''}
                                                        onChange={handleForm}
                                                        error={errors.productlocation_max_stock != null}
                                                        type="number"
                                                        multiline={true}
                                                        InputProps={{
                                                            inputProps: {
                                                                min: 0,
                                                                max: 100
                                                            }
                                                        }}
                                                        helperText={
                                                            errors.productlocation_max_stock?.type === 'min'
                                                                ? `${t('validationErrorMessages.minNumber')} 0 `
                                                                : errors.productlocation_max_stock?.type === 'max'
                                                                    ? `${t('validationErrorMessages.MaxNumber')} 2147483647 `
                                                                    : ""
                                                        }
                                                    />
                                                </div>
                                            </>
                                        )}
                                        
                                        <div className="col-md-6 mb-2">
                                            <div className="row np-row">
                                                <div className="col-md-12">
                                                    <FormControlLabel 
                                                        control={
                                                            <Checkbox 
                                                                name="productlocation_use_stock" 
                                                                checked={(formData.productlocation_use_stock === 0 || !formData.productlocation_use_stock) ? false : true} 
                                                                onChange={handleCheck} 
                                                            />
                                                        } 
                                                        label={t('productComponent.stockProduct')} 
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="d-flex justify-content-center mt-4">
                                <button className="bold-btn bold-btn-light" onClick={
                                    () => {
                                        handleStep(2);
                                    }
                                }
                                >{t('back')}</button>
                                <button className="bold-btn bold-btn-dark"  onClick={(e) => {
                                    e.preventDefault();
                                    handleStep(4);
                                }}>{t('next')}</button>
                            </div>
                        </div>
                    )
                }

                {
                    activeStep === 4 &&
                    (
                        <div className="container bold-container-content">
                            <div className="container row">
                                <div className="form-title col-md-12">
                                    {t('images')}
                                </div>
                                <div className="col-md-12 mb-2">
                                    <BoldFilesUpload returnImages={handleImages} multiple={true} value={formData.images ?? ''} />
                                </div>
                                <div className="row d-flex justify-content-center">
                                    <div className="col-md-3 d-flex justify-content-around">
                                        <button className="bold-btn bold-btn-light" onClick={
                                            (e) => {
                                                if (formData.product_view !== "MODAL_DRINK_SIMPLE" && formData.product_view !== "MODAL_DRINK_SEMICUSTOM" && formData.product_dessert !== true) {
                                                    handleStep((locations.length === 1 && id === undefined) ? 3 : 2);
                                                } else {
                                                    handleStep(1);
                                                }
                                            }
                                        }
                                        >{t('back')}</button>
                                        <button className="bold-btn bold-btn-light" onClick={handleCancel} >{t('cancel')}</button>
                                        {id === undefined && <button className="bold-btn bold-btn-dark" onClick={handleSubmitLocal}>{t('submit')}</button>}
                                        {id && !disableButton && <button className="bold-btn bold-btn-dark" onClick={handleUpdate}>{t('update')}</button>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </>
        </div>
    )
}

export default ProductsForms;